/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/19/2019
 * @Example
 */
import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import * as W09F6000Actions from "../../../../redux/W0X/W09F6000/W09F6000_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
import {Row, Col, FormGroup} from "react-bootstrap"
import withStyles from "@material-ui/core/styles/withStyles";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import {Column} from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Typography,
    DatePicker,
    Button,
    Checkbox,
    TextInput,
    Dropdown,
    TreeView,
    Label,
} from 'diginet-core-ui/components';
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from "@material-ui/core/Divider";
import InlineSVG from "react-inlinesvg";
import _ from "lodash";

const styles = (theme) => ({
    panel: {
        transition: 'all .4s'
    },
    panelForm: {
        transition: 'all .5s',
        opacity: 1
    },
    hiddenOpacity: {
        opacity: 0
    },
    leftPanelMinimum: {
        width: '74px',
    },
    rightPanelMinimum: {
        width: 'calc(100% - 74px)'
    },
    titleColorFilter: {
        backgroundColor: theme.palette.primary.main + ' !important',
        '& svg': {
            '& path': {
                fill: theme.palette.primary.contrastText
            }
        }
    },
    modalHeader:{
        "& > div > .DGN-UI-Typography":{
            width: "100%"
        }
    }
});

class W09F6000 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.filter = {
            limit: 15,
            skip: 0,
        };
        const filters = this.getDataFilter(props.dataDefaults);
        this.filter = filters;
        this.state = {
            loading: false,
            loadingOrgChartID: false,
            loadingLaborObjectID: false,
            loadingCboDirectManager: false,
            loadingDutyID: false,
            loadingProject: false,
            loadingStatusID: false,
            isSaving: false,
            dataDepartments: [],
            dataOrgChart: [],
            dataTeam: [],
            dataCboProjects: {
                rows: [],
                total: 0
            },
            dataCboDirectManager: {
                rows: [],
                total: 0
            },
            filters,
            dataLaborObject: [],
            dataGrid: [],
            totalDataGrid: 0,
            minimum: false,

            DateJoinFrom: null,
            DateJoinTo: null,
            PreUsingPeriodFrom: null,
            PreUsingPeriodTo: null,

            selectedRowKeys: [],
            isShowSelected: false,
        };
        this.isSelecting = false;
        this.tmpSelectedRowKeys = [];
        this.tmpCurrentSelectedRowKeys = [];
        this.tmpSelectedRowData = [];
        this.tmpDataSelectedRowKeys = [];
        // this.tmpDataSaveSelectedEmployee = [];
        this.chooseOnSelect = false;
        this.selectAll = false;
        this.changePage = false;
        this.isChangeShow = false;
        this.filterCboProjects = {
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.filterCboDirectManager = {
            timer: null,
            skip: 0,
            limit: 50,
            strSearch: ""
        };
        this.genders = [
            {
                SexID: 0,
                SexName: Config.lang("NamU")
            },
            {
                SexID: 1,
                SexName: Config.lang("Nu")
            }
        ];
        this.dataGrid = null;
    }

    loadCboDuty = () => {
        this.setLoading('DutyID', true);
        this.props.generalActions.getCboDuty2((errors) => {
            this.setLoading('DutyID', false);
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    };

    _generateDataOrg = (dataSource, valueExpr, parentIdExpr) => {
        return dataSource.map((e) => {
            if (e[valueExpr] && e[parentIdExpr] && e[valueExpr] === e[parentIdExpr]) {
                delete e[parentIdExpr];
                e.expanded = true;
            } else if (!e[parentIdExpr]) {
                e.expanded = true;
            }
            return e;
        });
    };

    loadDataOrgChart = () => {
        this.props.generalActions.getOrgCharts({}, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const dataSource = this._generateDataOrg(data, "OrgChartID", "OrgChartParentID");
                this.setState({dataOrgChart: dataSource});
            }
        });
    };


    loadCboProjects = (isReset) => {
        const params = {
            HostID: "",
            FormID: "W09F6000",
            Language: Config.language || "84",
            skip: this.filterCboProjects.skip,
            limit: this.filterCboProjects.limit,
            search: this.filterCboProjects.strSearch
        };
        this.setLoading('Project', true);
        this.props.w09f6000Actions.getCboProjects(params, (errors, data) => {
            this.setLoading('Project', false);
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                const {dataCboProjects} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboDirectManager = (isReset = false) => {
        const {dataCboDirectManager} = this.state;
        const params = {
            Type: "DirectManagerID",
            FormID: _.get(this.props, "FormID", ""),
            Language: Config.language || "84",
            Mode: 0,
            skip: this.filterCboDirectManager.skip,
            limit: this.filterCboDirectManager.limit,
            SearchValue: this.filterCboDirectManager.strSearch,
            SearchValue2: "",
            SearchValueUnicodeCombine: "",
            TranMonth: 0,
            TranYear: 0,
        };
        this.setState({loadingCboDirectManager: true});
        this.props.w09f6000Actions.getCboDirectManager(params, (err, data) => {
            this.setState({loadingCboDirectManager: false});
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            if (data) {
                const dataRows = _.get(data, "rows", data) ?? [];
                const totalRows = _.get(data, "total", _.size(data)) ?? 0;
                this.setState({
                    dataCboDirectManager: {
                        rows: isReset ? dataRows : _.concat(dataCboDirectManager.rows, dataRows),
                        total: totalRows
                    }
                })
            }
        })
    };

    loadCboLaborObject = () => {
        this.setLoading('LaborObjectID', true);
        this.props.w09f6000Actions.getCboLaborObject({}, (errors, data) => {
            this.setLoading('LaborObjectID', false);
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                this.setState({dataLaborObject: data});
            }
        });
    };

    loadCboStatusWork = () => {
        this.setLoading('StatusID', true);
        this.props.generalActions.getCboStatusWork((errors) => {
            this.setLoading('StatusID', false);
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    };

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({['loading' + key]: status});
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {dataDefaults, open} = this.props;
        const arrayKeys = (dataDefaults && dataDefaults.dataSelected) ? dataDefaults.dataSelected.map(d => d.EmployeeID) : [];
        if (JSON.stringify(prevProps.dataDefaults) !== JSON.stringify(this.props.dataDefaults)
            || (JSON.stringify(arrayKeys) !== JSON.stringify(this.tmpSelectedRowKeys) && prevProps.open !== open && open)
        ) {
            const dataDefaults = this.props.dataDefaults;
            this.setState({filters: this.getDataFilter(dataDefaults)});
            if (dataDefaults && dataDefaults.selectedRowKeys) {
                this.tmpSelectedRowKeys = [];
                dataDefaults.selectedRowKeys.forEach(select => {
                    if (this.tmpSelectedRowKeys.indexOf(select) < 0) {
                        this.tmpSelectedRowKeys.push(select);
                    }
                });
            }
            if (dataDefaults && dataDefaults.dataSelected) {
                this.tmpSelectedRowData = [];
                dataDefaults.dataSelected.forEach(select => {
                    if (this.tmpSelectedRowData.indexOf(select) < 0) {
                        this.tmpSelectedRowData.push(select);
                    }
                });
            }
            // if (dataDefaults && dataDefaults.isLoaded) {
            this.loadGrid(true);
            // }
        }
    }

    componentDidMount() {
        const {dataDefaults} = this.props;
        this.loadCboDuty();
        this.loadCboProjects();
        this.loadDataOrgChart();
        this.loadCboLaborObject();
        this.loadCboStatusWork();
        this.loadCboDirectManager();

        if (dataDefaults) {
            if (dataDefaults.selectedRowKeys && dataDefaults.selectedRowKeys.length > 0) {
                dataDefaults.selectedRowKeys.forEach(select => {
                    if (this.tmpSelectedRowKeys.indexOf(select) < 0) {
                        this.tmpSelectedRowKeys.push(select);
                    }
                });
            }
            if (dataDefaults && dataDefaults.dataSelected) {
                this.tmpSelectedRowData = [];
                dataDefaults.dataSelected.forEach(select => {
                    if (this.tmpSelectedRowData.indexOf(select) < 0) {
                        this.tmpSelectedRowData.push(select);
                    }
                });
            }
        }
        this.loadGrid(true);
    }

    getDataFilter = (dataDefaults) => {
        return {
            ...this.filter,
            ...dataDefaults,
            strSearch: dataDefaults?.strSearch || "",
            OrgChartID: dataDefaults?.OrgChartID && dataDefaults.OrgChartID.length > 0 ? dataDefaults.OrgChartID : [],
            DirectManagerID: _.get(dataDefaults, "DirectManagerID", []) || [],
            LaborObjectID: dataDefaults?.LaborObjectID && dataDefaults.LaborObjectID.length > 0 ? dataDefaults.LaborObjectID : [],
            DutyID: dataDefaults?.DutyID && dataDefaults.DutyID.length > 0 ? dataDefaults.DutyID : [],
            ProjectID: dataDefaults?.ProjectID && dataDefaults.ProjectID.length > 0 ? dataDefaults.ProjectID : [],
            StatusID: dataDefaults?.StatusID && dataDefaults.StatusID.length > 0 ? dataDefaults.StatusID : [],
            SexID: dataDefaults?.SexID && dataDefaults.SexID.length > 0 ? dataDefaults.SexID : [],
            DateJoinFrom: dataDefaults?.DateJoinFrom || null,
            DateJoinTo: dataDefaults?.DateJoinTo || null,
            PreUsingPeriodFrom: dataDefaults?.PreUsingPeriodFrom || null,
            PreUsingPeriodTo: dataDefaults?.PreUsingPeriodTo || null,
            NotJoinTrain: dataDefaults?.NotJoinTrain || false,
        };
    };

    handleChange = (key, e) => {
        const {filters} = this.state;
        if (!key) return false;
        const value = _.get(e, "value", _.get(e, "target.value", ""));
        this.setState({filters: {...filters, [key]: value}}, () => {
            if (key === "NotJoinTrain") {
                setTimeout(() => {
                    this.onFilter();
                    if (this.props && this.props.onCheckedNotJoinTrain) {
                        this.props.onCheckedNotJoinTrain(e.value);
                    }
                })
            }
        })
    };

    loadGrid = (isReset) => {
        const {
            strSearch, LaborObjectID, OrgChartID, DutyID,
            ProjectID, StatusID, SexID, DateJoinFrom, DateJoinTo, PreUsingPeriodFrom, PreUsingPeriodTo, NotJoinTrain,
            DirectManagerID
        } = this.state.filters;
        const {limit, skip} = this.filter;
        const {FormID, loadParams, AttendanceDate, ShiftID} = this.props;
        // const ProjectID = Project ? Project.map(pro => pro.ProjectID && pro.ProjectID) : [];
        const params = {
            Employee: strSearch,
            LaborObjectID: LaborObjectID && LaborObjectID.length > 0 ? JSON.stringify(LaborObjectID) : "",
            OrgChartID: OrgChartID && OrgChartID.length > 0 ? JSON.stringify(OrgChartID) : "",
            DutyID: DutyID && DutyID.length > 0 ? JSON.stringify(DutyID) : "",
            ProjectID: ProjectID && ProjectID.length > 0 ? JSON.stringify(ProjectID) : "",
            StatusID: StatusID && StatusID.length > 0 ? JSON.stringify(StatusID) : "",
            SexID: SexID && SexID.length > 0 ? JSON.stringify(SexID) : "",
            DateJoinFrom: DateJoinFrom,
            DateJoinTo: DateJoinTo,
            DirectManagerID: !!_.size(DirectManagerID) ? JSON.stringify(DirectManagerID) : "",
            PreUsingPeriodFrom: PreUsingPeriodFrom,
            PreUsingPeriodTo: PreUsingPeriodTo,
            FormID: FormID,
            Language: Config.language || "84",
            ...loadParams,
            limit: limit,
            skip: skip
        };
        if (AttendanceDate) params.AttendanceDate = AttendanceDate;
        if (ShiftID) params.ShiftID = ShiftID;

        if (this.props && this.props.FormID === 'W38F2001') {
            params.NotJoinTrain = NotJoinTrain;
            params.TrainingCourseID = this.props.TrainingCourseID;
        }
        if (!isReset) {
            this.changePage = true;
        }
        this.setState({ loading: true });
        this.props.w09f6000Actions.getGridEmployees(params, (errors, data) => {
            this.setState({ loading: false });
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            let selectedRowKeys = this.tmpSelectedRowKeys;
            this.setState({
                dataGrid: data.rows ? data.rows : data,
                totalDataGrid: data.total,
                selectedRowKeys: selectedRowKeys
            });
        });
    };

    onFilter = () => {
        this.filter.skip = 0;
        this.loadGrid();
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    };

    onChangePerPage = (per) => {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    };

    onSelect = () => {
        let selectedRowKeys = this.tmpSelectedRowKeys;
        if (this.selectAll) {
            //chạy api để lấy tất cả....
            //rồi mới insert được
        }
        this.tmpDataSelectedRowKeys = [];
        const {FormID, onChosen} = this.props;

        // list form không cần lưu bảng tạm. Lấy trực tiếp danh sách nhân viên đc từ popup.
        const exceptFormID = ['W39F1000'];
        if(exceptFormID.includes(FormID) && onChosen){
            onChosen(this.tmpSelectedRowKeys, this.tmpSelectedRowData);
            return;
        }

        if (selectedRowKeys && selectedRowKeys.length > 0) {
            this.setState({isSaving: true});
            const params = {
                EmployeeID: JSON.stringify(selectedRowKeys),
                HostID: "",
                FormID: FormID ? FormID : ""
            };
            this.props.w09f6000Actions.saveSelectedEmployee(params, (errors) => {
                this.setState({isSaving: false});
                if (errors) {
                    let message = Config.lang("Loi_chua_xac_dinh");
                    switch (errors.code) {
                        case "F6000E001":
                            message = "EmployeeID " + Config.lang("Bat_buoc");
                            break;
                        default:
                            break;
                    }
                    Config.popup.show('INFO', message);
                    return false;
                }
                // this.tmpDataSaveSelectedEmployee = this.tmpSelectedRowKeys;
                if (onChosen) onChosen(this.tmpSelectedRowKeys);
            });
        }
    };

    // setSelectedEmployees = (e) => {
    //     const currentSelectedRowKeys = e.currentSelectedRowKeys;
    //     const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
    //     if (currentDeselectedRowKeys.length === 1) {
    //         if (!this.selectAll) {
    //             this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter((e) => {
    //                 return e !== currentDeselectedRowKeys[0];
    //             });
    //         } else {
    //             this.tmpDeselectedRowKeys.push(currentDeselectedRowKeys[0]);
    //         }
    //     } else if (currentDeselectedRowKeys.length > 1) {
    //         this.tmpSelectedRowKeys = [];
    //         this.tmpDeselectedRowKeys = [];
    //         this.selectAll = false;
    //     } else if (currentSelectedRowKeys.length === 1) {
    //         if (!this.selectAll) {
    //             this.tmpSelectedRowKeys.push(currentSelectedRowKeys[0]);
    //         } else {
    //             this.tmpDeselectedRowKeys = this.tmpDeselectedRowKeys.filter((e) => {
    //                 return e !== currentSelectedRowKeys[0];
    //             });
    //         }
    //     } else if (currentSelectedRowKeys.length > 1) {
    //         this.tmpSelectedRowKeys = [];
    //         this.tmpDeselectedRowKeys = [];
    //         this.selectAll = true;
    //     }
    // };

    setSelectedEmployees = (e) => {
        const currentSelectedRowKeys = e.currentSelectedRowKeys;
        const currentSelectedRowData = e.selectedRowsData;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        if (currentDeselectedRowKeys.length > 0) {
            if (this.changePage) {
                this.changePage = false;
                return false;
            }
            if (this.isChangeShow) {
                this.isChangeShow = false;
                return false;
            }

            this.tmpSelectedRowData = this.tmpSelectedRowData.filter((e) => {
                return currentDeselectedRowKeys.indexOf(e.EmployeeID) < 0;
            });
            this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter((e) => {
                return currentDeselectedRowKeys.indexOf(e) < 0;
            });
            this.tmpDataSelectedRowKeys = this.tmpDataSelectedRowKeys.filter((e) => {
                return currentDeselectedRowKeys.indexOf(e) < 0;
            });
        } else if (currentSelectedRowKeys.length > 0) {
            currentSelectedRowKeys.forEach((val) => {
                if (this.tmpSelectedRowKeys.indexOf(val) < 0) {
                    this.tmpSelectedRowKeys.push(val);
                    const data = currentSelectedRowData.find(d => d.EmployeeID === val);
                    if (data) this.tmpSelectedRowData.push(data);
                }

                if (this.tmpDataSelectedRowKeys.indexOf(val) < 0) {
                    this.tmpDataSelectedRowKeys.push(val);
                }
            });
        }
    };

    onSelectionChanged = async (e) => {
        if (!this.isSelecting) {
            this.isSelecting = true;
            await this.setSelectedEmployees(e);
            this.setState({
                selectedRowKeys: e.selectedRowKeys
            }, () => {
                this.isSelecting = false;
                this.changePage = false;
                this.isChangeShow = false;
            });
        }
    };

    collapsePanel = (flag) => {
        if (typeof flag !== "undefined") {
            this.setState({minimum: !!flag});
        } else {
            this.setState({minimum: !this.state.minimum});
        }
    };

    changeTypeShow = (e) => {
        if (!e) return false;
        this.isChangeShow = true;
        this.setState({isShowSelected: e.target.checked});
    };

    getTotalSelectedRowKey = () => {
        return `(${this.tmpSelectedRowKeys.length})`;
    };

    onClose = () => {
        const {onClose, dataDefaults} = this.props;
        if (!this.chooseOnSelect) {
            if (dataDefaults && dataDefaults.selectedRowKeys) {
                this.tmpSelectedRowKeys = [];
                dataDefaults.selectedRowKeys.forEach(select => {
                    if (this.tmpSelectedRowKeys.indexOf(select) < 0) {
                        this.tmpSelectedRowKeys.push(select);
                    }
                });
            }
            if (dataDefaults && dataDefaults.dataSelected) {
                this.tmpSelectedRowData = [];
                dataDefaults.dataSelected.forEach(select => {
                    if (this.tmpSelectedRowData.indexOf(select) < 0) {
                        this.tmpSelectedRowData.push(select);
                    }
                });
            }
            this.setState({selectedRowKeys: this.tmpSelectedRowKeys});
        }
        if (onClose) onClose();
    };

    //true: Con key chua duoc select; false: da select het..
    _checkSelectedRowsKeys = (dataSource) => {
        let isCheck = false;
        if (dataSource && dataSource.length > 0 && this.tmpSelectedRowKeys.length > 0) {
            const dataKeys = dataSource.map(d => d.EmployeeID);
            for (let key of this.tmpSelectedRowKeys) {
                if (!dataKeys.includes(key)) {
                    isCheck = true;
                    break;
                }
            }
        }
        return isCheck;
    };

    render() {
        const {classes, open, getCboDuty, FormID, getCboStatusWork} = this.props;
        const {
            loading, isSaving, dataLaborObject, dataGrid, totalDataGrid,
            selectedRowKeys, dataCboProjects, minimum, DateJoinFrom, DateJoinTo, PreUsingPeriodFrom,
            PreUsingPeriodTo, isShowSelected, dataCboDirectManager, loadingCboDirectManager,
            filters
        } = this.state;
        const {
            loadingLaborObjectID,
            loadingDutyID,
            loadingProject,
            loadingStatusID,
            dataOrgChart
        } = this.state;
        const {
            OrgChartID,
            LaborObjectID,
            DirectManagerID,
            DutyID,
            ProjectID,
            StatusID,
            SexID,
            NotJoinTrain,
            strSearch
        } = filters;
        let _dataGrid = dataGrid;
        let _selectedRowKeys = selectedRowKeys;
        if (isShowSelected) {
            this.tmpCurrentSelectedRowKeys = selectedRowKeys;
            if (this.tmpSelectedRowData) {
                _dataGrid = this.tmpSelectedRowData;
                if (_selectedRowKeys.length === this.tmpSelectedRowKeys.length) this.isChangeShow = false;
                _selectedRowKeys = this.tmpSelectedRowKeys;
            }

        } else if (this.isChangeShow) {
            if (!this._checkSelectedRowsKeys(_dataGrid)) this.isChangeShow = false;
            // if (_selectedRowKeys.length === this.tmpSelectedRowKeys.length) this.isChangeShow = false;
        }
        return (
            <>
                <Modal open={open} pressESCToClose onClose={this.onClose}>
                    <ModalHeader className={classes.modalHeader}>
                        <ActionToolbar
                            alignment={"flex-end"}
                            title={Config.lang("Danh_sach_nhan_vien")}
                            showBorder={false}
                            upperCase={false}
                            className={"text-uppercase"}
                            style={{
                                marginTop: 0,
                                width: "100%"
                            }}
                        >
                            <Button
                                size="large"
                                text={Config.lang('Dong1')}
                                viewType="outlined"
                                typeButton={'cancel'}
                                disabled={!!isSaving}
                                className={'mgr5'}
                                style={{textTransform: 'uppercase'}}
                                onClick={this.onClose}/>
                            <Button
                                size="large"
                                text={Config.lang('Chon')}
                                viewType="filled"
                                color={"info"}
                                disabled={!!isSaving}
                                style={{textTransform: 'uppercase'}}
                                onClick={this.onSelect}/>
                        </ActionToolbar>
                    </ModalHeader>
                    <Divider/>
                    <ModalBody>
                        <Row>
                            <Col xs={12} sm={4} md={4} lg={4}
                                 className={classes.panel + " " + (minimum && window.innerWidth > 768 ? classes.leftPanelMinimum : "")}>
                                <Row>
                                    <ActionToolbar
                                        alignment={"space-between"}
                                        // title={Config.lang("Loc_chon_nhan_vien")}
                                        showBorder={false}
                                        upperCase={false}
                                        className={minimum ? classes.titleColorFilter : ""}
                                        allwaysTop={false}
                                        style={{
                                            marginTop: 0,
                                            backgroundColor: '#F7F9FC',
                                            padding: '0 15px',
                                            marginBottom: 15,
                                            fontSize: '1rem'
                                        }}
                                    >
                                        <div className={"display_row align-center valign-middle"}>
                                            <IconButton color="inherit" aria-label="menu"
                                                        onClick={() => this.collapsePanel()}>
                                                <InlineSVG src={require('../../../../assets/images/filter.svg')}/>
                                            </IconButton>
                                            {!minimum && <Typography type="h6" className={"align-center"}>
                                                {Config.lang("Loc_chon_nhan_vien")}
                                            </Typography>}
                                        </div>
                                        {!minimum && <IconButton size={"small"} color="inherit" aria-label="menu"
                                                                 onClick={() => this.collapsePanel()}>
                                            <ChevronLeftIcon fontSize={"large"}/>
                                        </IconButton>}
                                    </ActionToolbar>
                                </Row>
                                <div className={classes.panelForm + " form-field " + (minimum ? "hide" : "")}>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <div className={"display_row align-center align-between"}
                                             style={{marginLeft: -10}}>
                                            <Checkbox
                                                checked={isShowSelected}
                                                label={Config.lang("Hien_thi_du_lieu_da_chon")}
                                                color={'primary'}
                                                onChange={this.changeTypeShow}
                                            />
                                            <Typography>
                                                {this.getTotalSelectedRowKey()}
                                            </Typography>
                                        </div>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <TextInput
                                                    style={{marginBottom: 0}}
                                                    label={Config.lang('Nhan_vien')}
                                                    placeholder={Config.lang('Ma_nhan_vien_Ten_nhan_vien')}
                                                    startIcon="Search"
                                                    viewType="outlined"
                                                    defaultValue={strSearch}
                                                    onChange={(e) => this.handleChange('strSearch', e)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Dropdown
                                                    multiple
                                                    clearAble
                                                    dataSource={dataOrgChart}
                                                    valueExpr={"OrgChartID"}
                                                    displayExpr={"OrgName"}
                                                    label={Config.lang("Co_cau_to_chuc")}
                                                    keyExpr={'OrgName'}
                                                    onChange={(e, data) => this.handleChange("OrgChartID", data)}
                                                    value={OrgChartID}
                                                    viewType={'outlined'}
                                                    placeholder={Config.lang("Co_cau_to_chuc")}
                                                    style={{margin: 0}}
                                                >
                                                    <TreeView
                                                        multiple
                                                        allowSearch
                                                        dataSource={dataOrgChart}
                                                        disabledRelevantValue
                                                        displayExpr={"OrgName"}
                                                        id={"OrgChartID"}
                                                        multipleValueMode="single"
                                                        onChange={(e) => this.handleChange("OrgChartID", e)}
                                                        parentID={"OrgChartParentID"}
                                                        value={OrgChartID}
                                                        valueExpr={"OrgChartID"}
                                                    />
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Dropdown
                                                    dataSource={dataLaborObject}
                                                    allowSearch
                                                    multiple
                                                    clearAble
                                                    selectBox
                                                    closeAfterSelect={false}
                                                    style={{marginBottom: 0}}
                                                    disabled={loadingLaborObjectID}
                                                    displayExpr={'LaborObjectName'}
                                                    valueExpr={'LaborObjectID'}
                                                    label={Config.lang('Doi_tuong_lao_dong')}
                                                    onChange={(e) => this.handleChange("LaborObjectID", e)}
                                                    value={LaborObjectID}
                                                    viewType={'outlined'}
                                                    placeholder={Config.lang('Doi_tuong_lao_dong')}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Dropdown
                                                    selectBox
                                                    allowSearch
                                                    multiple
                                                    closeAfterSelect={false}
                                                    clearAble
                                                    style={{marginBottom: 0}}
                                                    dataSource={getCboDuty}
                                                    disabled={loadingDutyID}
                                                    displayExpr={'DutyName'}
                                                    valueExpr={'DutyID'}
                                                    label={Config.lang('Chuc_danh_cong_viec')}
                                                    onChange={(e) => this.handleChange("DutyID", e)}
                                                    value={DutyID}
                                                    viewType={'outlined'}
                                                    placeholder={Config.lang('Chuc_danh_cong_viec')}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Dropdown
                                                    selectBox
                                                    allowSearch
                                                    multiple
                                                    closeAfterSelect={false}
                                                    clearAble
                                                    dataSource={dataCboProjects.rows}
                                                    total={dataCboProjects.total}
                                                    skip={this.filterCboProjects.skip}
                                                    limit={this.filterCboProjects.limit}
                                                    displayExpr={'{ProjectID} - {ProjectName}'}
                                                    keyExpr={'ProjectName'}
                                                    valueExpr={'ProjectID'}
                                                    value={ProjectID}
                                                    loading={loadingProject}
                                                    viewType={'outlined'}
                                                    label={Config.lang('Du_an')}
                                                    placeholder={Config.lang('Du_an')}
                                                    onChange={(e) => this.handleChange("ProjectID", e)}
                                                    onInput={(e) => {
                                                        if (this.filterCboProjects.timer) clearTimeout(this.filterCboProjects.timer);
                                                        this.filterCboProjects.strSearch = e.target.value;
                                                        this.filterCboProjects.timer = setTimeout(() => {
                                                            this.filterCboProjects.skip = 0;
                                                            this.filterCboProjects.limit = 50;
                                                            this.loadCboProjects(true);
                                                        }, 700);
                                                    }}
                                                    onLoadMore={(e) => {
                                                        this.filterCboProjects.skip = e.skip;
                                                        this.filterCboProjects.limit = e.limit;
                                                        this.loadCboProjects();
                                                    }}
                                                    style={{margin: 0}}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Dropdown
                                                    selectBox
                                                    allowSearch
                                                    multiple
                                                    clearAble
                                                    closeAfterSelect={false}
                                                    style={{marginBottom: 0}}
                                                    dataSource={getCboStatusWork}
                                                    disabled={loadingStatusID}
                                                    displayExpr={'StatusName'}
                                                    valueExpr={'StatusID'}
                                                    label={Config.lang('Trang_thai_lam_viec')}
                                                    onChange={(e) => this.handleChange("StatusID", e)}
                                                    value={StatusID}
                                                    viewType={'outlined'}
                                                    placeholder={Config.lang('Trang_thai_lam_viec')}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Dropdown
                                                    selectBox
                                                    allowSearch
                                                    multiple
                                                    clearAble
                                                    closeAfterSelect={false}
                                                    style={{marginBottom: 0}}
                                                    dataSource={this.genders}
                                                    displayExpr={'SexName'}
                                                    valueExpr={'SexID'}
                                                    label={Config.lang('Gioi_tinh')}
                                                    onChange={(e) => this.handleChange("SexID", e)}
                                                    value={SexID}
                                                    viewType={'outlined'}
                                                    placeholder={Config.lang('Gioi_tinh')}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Dropdown
                                                    selectBox
                                                    allowSearch
                                                    multiple
                                                    closeAfterSelect={false}
                                                    clearAble
                                                    dataSource={dataCboDirectManager.rows}
                                                    total={dataCboDirectManager.total}
                                                    skip={this.filterCboDirectManager.skip}
                                                    limit={this.filterCboDirectManager.limit}
                                                    displayExpr={'{DirectManagerID} - {DirectManagerName}'}
                                                    keyExpr={'DirectManagerID'}
                                                    valueExpr={'DirectManagerID'}
                                                    value={DirectManagerID}
                                                    loading={loadingCboDirectManager}
                                                    viewType={'outlined'}
                                                    label={Config.lang("Nguoi_quan_ly_truc_tiep")}
                                                    placeholder={Config.lang("Nguoi_quan_ly_truc_tiep")}
                                                    onChange={(e) => this.handleChange("DirectManagerID", e)}
                                                    onInput={(e) => {
                                                        if (this.filterCboDirectManager.timer) clearTimeout(this.filterCboDirectManager.timer);
                                                        this.filterCboDirectManager.strSearch = e.target.value;
                                                        this.filterCboDirectManager.timer = setTimeout(() => {
                                                            this.filterCboDirectManager.skip = 0;
                                                            this.filterCboDirectManager.limit = 50;
                                                            this.loadCboDirectManager(true);
                                                        }, 700);
                                                    }}
                                                    onLoadMore={(e) => {
                                                        this.filterCboDirectManager.skip = e.skip;
                                                        this.filterCboDirectManager.limit = e.limit;
                                                        this.loadCboDirectManager();
                                                    }}
                                                    style={{margin: 0}}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Label
                                                    className={"mgb0 text-uppercase"}>{Config.lang("Ngay_vao_lam")}</Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <DatePicker
                                                    label={Config.lang("Tu")}
                                                    clearAble
                                                    width={"100%"}
                                                    style={{minWidth: "auto", marginBottom: 0}}
                                                    viewType={"outlined"}
                                                    max={DateJoinTo}
                                                    displayFormat={"DD/MM/YYYY"}
                                                    placeholder={"DD/MM/YYYY"}
                                                    onChange={e => this.handleChange("DateJoinFrom", e)}
                                                />
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <DatePicker
                                                    label={Config.lang("Den")}
                                                    clearAble
                                                    width={"100%"}
                                                    style={{minWidth: "auto", marginBottom: 0}}
                                                    viewType={"outlined"}
                                                    min={DateJoinFrom}
                                                    displayFormat={"DD/MM/YYYY"}
                                                    placeholder={"DD/MM/YYYY"}
                                                    onChange={e => this.handleChange("DateJoinTo", e)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    {FormID === "W51F1001" && <FormGroup>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Label
                                                    className={"mgb0 text-uppercase"}>{Config.lang("Ngay_cap_phat_CCLD_truoc")}</Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <DatePicker
                                                    label={Config.lang("Tu")}
                                                    clearAble={true}
                                                    width={"100%"}
                                                    style={{minWidth: "auto", marginBottom: 0}}
                                                    viewType={"outlined"}
                                                    max={PreUsingPeriodTo}
                                                    displayFormat={"DD/MM/YYYY"}
                                                    placeholder={"DD/MM/YYYY"}
                                                    onChange={e => this.handleChange("PreUsingPeriodFrom", e)}
                                                    value={PreUsingPeriodFrom}
                                                />
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <DatePicker
                                                    label={Config.lang("Den")}
                                                    clearAble={true}
                                                    width={"100%"}
                                                    style={{minWidth: "auto", marginBottom: 0}}
                                                    viewType={"outlined"}
                                                    min={PreUsingPeriodFrom}
                                                    displayFormat={"DD/MM/YYYY"}
                                                    placeholder={"DD/MM/YYYY"}
                                                    onChange={e => this.handleChange("PreUsingPeriodTo", e)}
                                                    value={PreUsingPeriodTo}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>}
                                    {FormID === "W38F2001" && <FormGroup>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Checkbox
                                                    checked={Boolean(NotJoinTrain) || false}
                                                    label={Config.lang("NV_chua_tham_gia_khoa_dao_tao")}
                                                    color={'primary'}
                                                    onChange={(_e, checked) => this.handleChange('NotJoinTrain', {value: checked})}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>}
                                    <FormGroup className={"display_row align_center valign-bottom"}>
                                        <Button
                                            startIcon={"search"}
                                            size="large"
                                            text={Config.lang('Tim_kiem')}
                                            viewType="outlined"
                                            color={"primary"}
                                            disabled={isSaving}
                                            style={{textTransform: 'uppercase'}}
                                            onClick={this.onFilter}/>
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col xs={12} sm={8} md={8} lg={8}
                                 className={classes.panel + " " + (window.innerWidth > 768 ? "bdl " + (minimum ? classes.rightPanelMinimum : "") : "")}>
                                <FormGroup>
                                    <GridContainer
                                        reference={ref => this.dataGrid = ref}
                                        totalItems={totalDataGrid}
                                        itemPerPage={this.filter.limit}
                                        skipPerPage={this.filter.skip}
                                        listPerPage={[15, 30, 45, 60]}
                                        dataSource={_dataGrid}
                                        disabled={isSaving}
                                        keyExpr={'EmployeeID'}
                                        gridProps={{
                                            style: {
                                                minHeight: 400,
                                            }
                                        }}
                                        pagerFullScreen={false}
                                        showBorders={false}
                                        columnAutoWidth={true}
                                        typeShort={window.innerWidth < 768}
                                        loading={loading}
                                        height={"calc(100vh - 180px)"}
                                        selection={{
                                            allowSelectAll: true,
                                            mode: "multiple",
                                            selectAllMode: "allPages",
                                            showCheckBoxesMode: "always"
                                        }}
                                        allowColumnResizing={true}
                                        selectedRowKey={_selectedRowKeys}
                                        onChangePage={this.onChangePage}
                                        onChangePerPage={this.onChangePerPage}
                                        onSelectionChanged={this.onSelectionChanged}
                                    >
                                        <Column
                                            caption={Config.lang("Ma_nhan_vien")}
                                            dataField={'EmployeeID'}
                                            alignment={"left"}
                                            width={150}
                                        />
                                        <Column
                                            caption={Config.lang("Ten_nhan_vien")}
                                            dataField={'EmployeeName'}
                                        />
                                        <Column
                                            caption={Config.lang("Chuc_danh_cong_viec")}
                                            dataField={'DutyName'}
                                            // width={200}
                                        />
                                        <Column
                                            caption={Config.lang("Co_cau_to_chuc")}
                                            dataField={'OrgChartName'}
                                            // width={200}
                                            // cellRender={(cellData) => this.cellRenderPopupAction(cellData)}
                                        />
                                        <Column
                                            caption={Config.lang("Du_an")}
                                            dataField={'ProjectName'}
                                            // width={200}
                                            // cellRender={(cellData) => this.cellRenderPopupAction(cellData)}
                                        />
                                        <Column
                                            caption={Config.lang("Doi_tuong_lao_dong")}
                                            dataField={'LaborObjectName'}
                                            // width={200}
                                        />
                                        <Column
                                            caption={Config.lang("Trang_thai_lam_viec")}
                                            dataField={'StatusName'}
                                            // width={200}
                                        />
                                        <Column
                                            caption={Config.lang("Gioi_tinh")}
                                            dataField={'SexName'}
                                            alignment={"center"}
                                            width={100}
                                        />
                                        <Column
                                            caption={Config.lang("Ngay_vao_lam")}
                                            dataField={'DateJoined'}
                                            alignment={"center"}
                                            dataType={"date"}
                                            format={"dd/MM/yyyy"}
                                            width={150}
                                        />
                                        <Column
                                            caption={Config.lang("Ngay_cap_phat_CCLD_truoc")}
                                            dataField={'UsingPeriodFrom'}
                                            visible={FormID === "W51F1001"}
                                            alignment={"center"}
                                            dataType={"date"}
                                            format={"dd/MM/yyyy"}
                                            width={150}
                                        />
                                        <Column
                                            caption={Config.lang("Cong_viec")}
                                            dataField={'WorkName'}
                                            // width={200}
                                        />
                                        <Column
                                            caption={Config.lang("Ngay_sinh")}
                                            dataField={'Birthdate'}
                                            alignment={"center"}
                                            dataType={"date"}
                                            format={"dd/MM/yyyy"}
                                            width={150}
                                        />
                                        <Column
                                            caption={Config.lang("So_dien_thoai")}
                                            width={200}
                                            alignment={"center"}
                                            dataField={'MobilePhone'}
                                            // cellRender={(cellData) => this.cellRenderPopupAction(cellData)}
                                        />
                                    </GridContainer>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

W09F6000.propTypes = {
    FormID: PropTypes.string,
    open: PropTypes.bool,
    loadParams: PropTypes.object,

    /**
     * ProjectID: ""
     * DutyID: ""
     * ...
     * dataSelected: [{{EmployeeID: "", EmployeeName: "",...},{EmployeeID: "", EmployeeName: "",...}]
     * selectedRowKeys: [EmpID, EmpID]
     * isLoaded: false
     */
    dataDefaults: PropTypes.object,

    onClose: PropTypes.func,
    onChosen: PropTypes.func
};

export default compose(connect((state) => ({
    getCboStatusWork: state.general.getCboStatusWork,
    getCboProjects: state.general.getCboProjects,
    getCboDuty: state.general.getCboDuty2
}), (dispatch) => ({
    w09f6000Actions: bindActionCreators(W09F6000Actions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
})), withStyles(styles))(W09F6000);
