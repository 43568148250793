import React                         from "react";
import {connect}                     from "react-redux";
import PropTypes                     from "prop-types";
import {bindActionCreators, compose} from "redux";
import {Row}                         from "react-bootstrap";
import withStyles                    from "@material-ui/core/styles/withStyles";
import Modal                         from "../../../common/modal/modal";
import Config                        from "../../../../config";
import * as W09F4000Actions          from "../../../../redux/W0X/W09F4000/W09F4000_actions";
import GridContainer                 from "../../../grid-container/grid-container";
import {Column}                      from "devextreme-react/data-grid";
import FormGroup                     from "@material-ui/core/FormGroup";
import {Typography}                  from "@material-ui/core";
import UserImage                     from "../../../common/user/user-image";

const styles = {
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain"
    },
};

class W09F4001Popup extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dataEmployeeDepartment: {
                rows: [],
                total: 0
            },
        };
        this.filter = {
            skip: 0,
            limit: 10
        }

    }
    loadListEmployeeDepartment = () => {
        const { data, isHiddenW09F4010 } = this.props;
        const {SearchValue, skip, limit} = this.filter;
        const params = {
            UserID: Config.profile.UserID,
            FormID: isHiddenW09F4010 ?  "W09F4010" : "W09F4000",
            Language: Config.language || 84,
            SearchValue: SearchValue,
            Value: "",
            OrgChartID: !isHiddenW09F4010 && data && data.OrgChartID ? data.OrgChartID : "",
            DutyID: isHiddenW09F4010 &&  data && data.DutyID ? data.DutyID : "",
            skip: String(skip),
            limit: String(limit)
        };
        this.setState({loading: true});
        this.props.w09f4000Actions.getListEmployeeDepartment(params, (error,data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if(data) {
                this.setState({
                    dataEmployeeDepartment: {
                        rows: data && data.rows ? data.rows : data,
                        total: data && data.total ? data.total : 0
                    }
                },() => {
                    this.setState({loading: false});
                });
            }
        });
    };

    componentDidMount = () => {
        this.loadListEmployeeDepartment();
    };

    onChangePage = page => {
        this.filter.skip = page * this.filter.limit;
        this.loadListEmployeeDepartment();
    };

    onChangePerPage = perPage => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadListEmployeeDepartment();
    };

    cellRenderEmployeeID = (e) => {
        const {data} = e;

        return (
            <div className={"display_row align-center"}>
                <UserImage data={data} width={32} height={32}/>
                <span>{data.EmployeeID}</span>
            </div>
        )

    };

    onDetail = e => {
        if (e.rowType !== "header" && e.rowType !== "filter" && e.data) {
            window.open(Config.getRootPath() + `W09F2000?EmployeeID=${e.data.EmployeeID}`)
        }
    };

    render() {
        const { open, title, data, isHiddenW09F4010, onClose } = this.props;
        const { skip, limit } =  this.filter;
        const { dataEmployeeDepartment, loading } = this.state;
        return (
            <>
                <Modal
                    open={open}
                    maxWidth={"md"}
                    fullWidth={true}
                    onClose={onClose}
                    title={title ? title : Config.lang("DHR_Danh_sach_nhan_vien")}
                >
                    <Modal.Content>
                        {!isHiddenW09F4010 &&
                            <FormGroup>
                                <Typography style={{fontSize:"1.5rem",fontWeight:600,marginTop:-14,paddingLeft:10}}>
                                    {`${data?.OrgChartID || ""} ${data && data.OrgChartName ? " - " + data.OrgChartName : ""}`}
                                </Typography>
                            </FormGroup>
                        }
                        {isHiddenW09F4010 &&
                        <FormGroup>
                            <Typography style={{fontSize:"1.5rem",fontWeight:600,marginTop:-14,paddingLeft:10}}>
                                {`${data?.DutyGroup[0]?.DutyID || ""} ${data?.DutyGroup[0]?.DutyName ? " - " + data?.DutyGroup[0]?.DutyName : ""}`}
                            </Typography>
                        </FormGroup>
                        }
                        <Row>
                            <GridContainer
                                style={{border: "none"}}
                                showBorders={true}
                                hoverStateEnabled={true}
                                dataSource={dataEmployeeDepartment && dataEmployeeDepartment.rows}
                                noDataText={Config.lang("DHR_No_data")}
                                onCellClick={this.onDetail}
                                loading={loading}
                                filterRow={{
                                    visible: true,
                                    showOperationChooser: false,
                                }}
                                itemPerPage={limit}
                                skipPerPage={skip}
                                pagerFullScreen={false}
                                totalItems={dataEmployeeDepartment && Number(dataEmployeeDepartment.total)}
                                typePaging={"normal"}
                                height={Config.getHeightGrid() - 180}
                                // onChangePage={this.onChangePage}
                                // onChangePerPage={this.onChangePerPage}
                            >
                                <Column
                                    caption={Config.lang("DHR_Ma_nhan_vien")}
                                    cellRender={this.cellRenderEmployeeID}
                                    dataField={'EmployeeID'}
                                />
                                <Column
                                    caption={Config.lang("DHR_Ten_nhan_vien")}
                                    dataField={'EmployeeName'}
                                />
                                {!isHiddenW09F4010 &&
                                    <Column
                                        caption={Config.lang("DHR_Chuc_danh_cong_viec")}
                                        dataField={'DutyName'}
                                    />
                                }

                                 <Column
                                        caption={Config.lang("DHR_Dien_thoai_noi_bo")}
                                        dataField={'CompanyTelephone'}
                                 />
                                <Column
                                    caption={Config.lang("DHR_Email")}
                                    dataField={'Email'}
                                />
                                <Column
                                    caption={Config.lang("DHR_Nam_sinh")}
                                    dataField={'BirthYear'}
                                />
                                <Column
                                    caption={Config.lang("DHR_Tham_nien")}
                                    dataField={'SeniorWork'}
                                />
                                <Column
                                    dataField={'OrgChartID'}
                                    visible={false}
                                />
                                <Column
                                    caption={Config.lang("Co_cau_to_chuc")}
                                    dataField={'OrgChartName'}
                                />
                            </GridContainer>
                        </Row>
                    </Modal.Content>
                </Modal>
            </>
        );
    }
}

W09F4001Popup.propTypes = {
    open: PropTypes.bool,
    mode: PropTypes.string,
    data: PropTypes.any,
    onClose: PropTypes.func
};

export default compose(
    connect(null,
        dispatch => ({
            w09f4000Actions: bindActionCreators(W09F4000Actions, dispatch),
        }), null,  { forwardRef: true }
    ),
    withStyles(styles)
)(W09F4001Popup);
