
import React                             from "react";
import {withStyles} from "@material-ui/core";
import "./W09F4000.scss";
import Config from "../../../../config";
import {Avatar, Typography} from "diginet-core-ui/components";
import {useTheme} from "diginet-core-ui/theme";
import {ButtonIcon, Typography as TypographyCore} from "diginet-core-ui/components";
const coreTheme = useTheme();
const {colors:{white, formal5, dark6, dark, system: { rest }}} = coreTheme;
const styles = {
    wrapperItem:       {
        boxShadow:    '0px 2px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        minWidth:     200,
        minHeight:    160,
        maxWidth:        400,
        backgroundColor: white,
        transition: 'all 0.1s',
        '&:hover':{
            boxShadow:    '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
        '& .overflow-one-line':{
            whiteSpace:'pre !important',
            maxWidth:'100% !important'
        }
    },
    filterContainer:        {
        padding:         "8px 16px",
        borderLeft: `1px solid ${formal5}`,
        textAlign: 'left'
    },
    footerItem:        {
        backgroundColor: '#FFFFFF',
        textAlign:       'center',
        padding:         '8px 16px',
        display:         'flex',
        justifyContent:  'center',
        alignItems:      'center'
    },
    layerImg:          {
        top:       25,
        left:      '50%',
        transform: 'translate(-50%, 0)',
        zIndex:    1,
        position:  'absolute',
    },
    userImageChild:    {
        cursor:                "pointer",
        border:                '1px solid white',
        boxShadow:             '1px 1px 2px #999',
        "&:not(:first-child)": {
            marginLeft: -8
        }
    },
    nodeNumber:        {
        position:     'absolute',
        bottom: -14,
        minWidth: 28,
        height: 28,
        zIndex:       1,
        borderRadius: '50%',
        lineHeight:   '24px',
        color:        '#FFF',
        cursor:       "pointer",
        left: 'calc(50% - 14px)',
        display:'flex !important',
        boxShadow:'0px 2px 2px rgba(0, 0, 0, 0.25)',
        border:`2px solid ${white}`
    },
    styleOrgChartName: {
        fontSize:      14,
        fontWeight:    700,
        color:         "#FFFFFF",
        textTransform: "uppercase",
        overflow:      "hidden",
        whiteSpace:    "nowrap",
        textOverflow:  "ellipsis",
    },
    styleTooltip:      {
        fontSize: 14
    },
    iconNumberText:    {
        width:          32,
        height:         32,
        zIndex:         100,
        border:         "1px solid #FFFFFF",
        borderRadius:   "50%",
        background:     "#7F828E",
        display:        "flex",
        justifyContent: "center",
        alignItems:     "center"
    },
    buttonFilter:{
        position:'absolute',
        right: 4,
        top:8
    },
    showMoreUser:{
        backgroundColor: rest,
        border:`1px solid ${white}`,
        width: 72,
        minWidth: 72,
        height: 72,
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "center",
        color: white,
        borderRadius: 72,
        cursor: 'pointer'
    },
};

class ItemPositionChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            tab: 0,
            showW09F4001Popup: false,
            itemDataNode: null,
            expanded: props.data ? props.data.expanded : true,
            isHiddenW09F4010: false,
            open: false,
            hoverImg: false,
            dataFilter: null
        };
        this.userImage = {};
    }

    showPopup = () => {
        const {data, onW09F4001Popup} = this.props;
        if (onW09F4001Popup && data && data.DutyGroup && data.DutyGroup.length > 0) onW09F4001Popup(data);
    };

    toggleShowItem = (e) => {
        e.stopPropagation();
        const {node, data, orgchart} = this.props;
        if (orgchart && data.countChildren > 0) {
            const nodeState = orgchart._getNodeState(node, "children");

            if (nodeState.exist && nodeState.visible) {
                orgchart.hideChildren(node);
                this._scrollToNode(node, orgchart);
            } else {
                orgchart.showChildren(node);
                this._scrollToNode(node, orgchart, 400);
            }
            this.setState({expanded: !this.state.expanded});
        }
    };

    _scrollToNode = (node, orgchart) => {
        const wrapperChart = orgchart.closest("div");
        if (wrapperChart && wrapperChart.offsetWidth && node) {
            setTimeout(() => {
                let lastTf = window.getComputedStyle(orgchart).transform;
                let containerPartWidth = orgchart.offsetWidth/2 - 100;
                let containerPartHeight = orgchart.offsetHeight/2 - 180;

                let newX = 0, newY = 0;
                if (lastTf === 'none') {
                    orgchart.style.transform = 'matrix(1, 0, 0, 1, 0, 0)';
                    newX = containerPartWidth - node.offsetLeft;
                    newY = containerPartHeight - node.offsetTop;
                    if (!lastTf.includes('3d')) {
                        orgchart.style.transform = 'matrix(1, 0, 0, 1, ' + newX + ', ' + newY + ')';
                    } else {
                        orgchart.style.transform = 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ' + newX + ', ' + newY + ', 0, 1)';
                    }
                } else {
                    let matrix = lastTf.split(',');
                    newX = containerPartWidth - node.offsetLeft;
                    newY = containerPartHeight - node.offsetTop;
                    if (!lastTf.includes('3d')) {
                        matrix[4] = newX * matrix[3];
                        matrix[5] = newY + ')';
                    } else {
                        matrix[12] = newX * matrix[3];
                        matrix[13] = newY;
                    }
                    orgchart.style.transform = matrix.join(',');
                }
            }, 700);
        }
    };
    
    componentDidMount() {
        if (this.userImage) {
            Object.keys(this.userImage).forEach(u => {
                this.userImage[u].userImage.addEventListener("mouseover", () => this.onMouseOver(this.userImage[u].userImage));
                this.userImage[u].userImage.addEventListener("mouseleave", () => this.onMouseLeave(this.userImage[u].userImage));
            });
            //     u.userImage.addEventListener("mouseleave", () => this.onMouseLeave(u.userImage));
            // });
        }

        const { listDutyFilter, data, isExpanded } = this.props;
        if((listDutyFilter.includes(data.DutyID) || isExpanded) && data?.IsFilter === 1){
            this._loadFilter(data);
        }
    }

    onMouseOver = (e) => {
        e.classList.add("w09f4000-user-image-hover-item");
    };
    onMouseLeave = (e) => {
        e.classList.remove("w09f4000-user-image-hover-item");
    };

    renderItemHover = (user) => {
        return (
             <div>
                {user  && <span>{`${user.UserName} - ${user.DutyName}`}<br/></span>}
            </div>
        )
    };

    _loadFilter = (data) => {
        if(this.loadingFilter) return;
        this.loadingFilter = true;

        const  {loadFilter} = this.props;
        const  {dataFilter} = this.state;

        if(dataFilter){
            this.setState({dataFilter: null},()=>{
                this.loadingFilter = false;
                if(this.refs["refBtnFilter"]) {
                    this.refs["refBtnFilter"].blur();
                }
            });
            loadFilter(data, ()=>{}, true);
            return null;
        }
        loadFilter({DutyID: data.DutyID}, data => {
            this.setState({dataFilter: data},(()=>{
                this.loadingFilter = false;
            }))
        })
    };

    render() {
        const  {classes, data} = this.props;
        const { dataFilter } = this.state;
        const DutyGroup = data && data.DutyGroup ? data.DutyGroup : [];

        let levelColor = 5 - parseInt(data.level || 0);
        if(levelColor < 1) levelColor = 1;

        const bgColor = coreTheme.colors?.[`formal${levelColor}`];
        const color = data.level > 2 ? dark : white;

        const lengthUser = DutyGroup?.length > 2 ? 3 : DutyGroup?.length; // length > 2 ? 3 : length ( cái cuối là số 99+)
        const widthUser = lengthUser > 1 ? `calc(100% / ${lengthUser})` : 72;
        const user = Config.getUser({EmployeeID: DutyGroup?.[0]?.EmployeeID});

        return (
            <>
                <div>
                    <div className={`display_row valign-middle ${classes.wrapperItem}`}
                         style={{borderTop: `8px solid ${bgColor}`, width: dataFilter ? 400 : 200}}
                         onClick={this.showPopup}
                    >
                        {/*Content*/}
                        <div style={{position: 'relative', maxWidth: 200}}>
                            <div className={classes.footerItem}
                                 style={{
                                     margin: 'auto',
                                     width: lengthUser > 2 ? '80%' : '100%',
                                     maxWidth: 200,
                                     justifyContent: 'space-around'
                                 }}
                            >
                                {[...new Array(lengthUser).keys()].map(key => {
                                    const style = { width:widthUser, overflow:' inherit', zIndex: key };
                                    if(key > 2) return null; // show maximum 2 Duty
                                    if(key === 2) return ( // show number length Duty if length > 2
                                        <div className={"display_row align-start valign-middle"}
                                             style={style}
                                             key={`DutyItem-${key}`}
                                        >
                                            <Typography type={'title1'}
                                                        className={classes.showMoreUser}
                                                        style={{zIndex: 3}}
                                            >
                                                {DutyGroup?.length > 101 ? '99+' : parseInt(DutyGroup?.length || 0) - 2}
                                            </Typography>
                                        </div>
                                    );

                                    const user = Config.getUser({EmployeeID: DutyGroup[key].EmployeeID});
                                    return( // show avatar Duty
                                        <div className={"display_row align-start valign-middle"}
                                             style={style}
                                             key={`DutyItem-${key}`}
                                        >
                                            <Avatar
                                                clearAble={false}
                                                width={72}
                                                height={72}
                                                readOnly
                                                data={{
                                                    [Config.lang("Nhan_vien")]:`${user?.EmployeeID || ""} - ${user?.EmployeeName || ""}`,
                                                    [Config.lang("Phong_ban")]:user?.DepartmentName ?? "",
                                                    [Config.lang("DHR_Chuc_vu")]:user?.DutyName ?? "",
                                                    [Config.lang("DHR_Ngay_vao_lam")]:Config.convertDate(user?.DateJoined,'','L') || "",
                                                }}
                                                hoverAble={true}
                                                style={{
                                                    border:`1px solid ${white}`,
                                                    backgroundColor: white
                                                }}
                                                src={user?.UserPictureURL}
                                            />
                                        </div>
                                    );
                                })}
                                </div>
                            <div className={"display_col align-center valign-middle"} style={{padding:"0 16px"}}>
                                <Typography type={'h3'}
                                            style={{height: 20}}
                                            className={"overflow-one-line"}
                                >
                                    {DutyGroup?.length === 1 && user.EmployeeName ? user.EmployeeName : ""}
                                </Typography>
                                <Typography type={'p1'}
                                            style={{color: dark6}}
                                            className={"overflow-one-line"}
                                >
                                    {user.DutyName || ""}
                                </Typography>
                            </div>
                            {data?.IsFilter === 1 &&
                                <ButtonIcon name={"FilterFilled"}
                                            viewBox={true}
                                            ref={"refBtnFilter"}
                                            viewType={"ghost"}
                                            className={classes.buttonFilter}
                                            size={'tiny'}
                                            onClick={() => this._loadFilter(data)}
                                />
                            }
                        </div>
                        {/*Filter*/}
                        {dataFilter &&
                            <div className={classes.filterContainer}>
                                <TypographyCore type={'h3'} style={{marginBottom: 8}}>
                                    {dataFilter[0]?.Label || ''}
                                </TypographyCore>

                                {!dataFilter.length && // Không có dữ liệu
                                    <TypographyCore type={'p1'}>
                                        {Config.lang("Khong_co_du_lieu")}
                                    </TypographyCore>
                                }

                                {dataFilter?.map(i => {
                                    return <TypographyCore key={i} type={'p1'}
                                                           style={{whiteSpace: 'initial'}}>
                                        {`${i.FilterValueName}: ${i.Total}`}
                                    </TypographyCore>
                                })}
                            </div>
                        }
                        {DutyGroup.length > 0 && data.countChildren > 0 &&
                            <Typography type="h6"
                                        className={`display_col align-center valign-middle ${classes.nodeNumber}`}
                                        style={{backgroundColor:bgColor, color: color}}
                                        onClick={this.toggleShowItem}
                            >
                                {parseInt(data?.countChildren || 0) > 99 ? '99+' : data?.countChildren}
                            </Typography>
                        }
                    </div>
                </div>
                <i className="edge verticalEdge topEdge fa" />
                <i className="edge horizontalEdge rightEdge fa" />
                <i className="edge horizontalEdge leftEdge fa" />
                <i className="edge verticalEdge bottomEdge fa" />
            </>
        );
    }

}

export default withStyles(styles, {withTheme: true})(ItemPositionChart);
